import axios from "axios";
import React from "react";
import ReactHtmlParser from 'react-html-parser';
import ReactMarkdown from 'react-markdown'

import './Resume.css';

function App() {
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        axios({
            url: "https://api-eu-central-1.graphcms.com/v2/ckv24kc3w21s901xl7go923kz/master",
            method: 'post',
            data: {
                query: `
                    query site {
                        snippets {
                            aboutme
                            legal {
                                html
                            }
                            whatDoIDo {
                                html
                            }
                            photo {
                                url
                            }
                            corporateLogo {
                                url
                            }
                        }
                        projects {
                            id
                            keywords
                            location
                            company
                            title
                            description
                        }
                    }
                  `
            }
        }
        ).then((response) => {
            setData(response.data);
        });
    }, []);


    return (
        <>
            <div className="logo-container w-container">
                <a href="/" aria-current="page" className="w-inline-block w--current"><img src={data?.data?.snippets?.[0]?.corporateLogo.url}
                    width="250" alt="" /></a>
            </div>
            <div className="container w-container">
                <div className="w-row">
                    <div className="w-col w-col-4 w-col-stack">
                        <div className="author-wrapper">
                            <div className="person-image">
                                <img src={data?.data?.snippets?.[0]?.photo.url} alt="" />
                            </div>
                            <div className="social-wrapper">
                                <div className="left-copyright-flex">
                                    {ReactHtmlParser(data?.data?.snippets?.[0]?.legal.html)}
                                </div>

                                <a href="#" className="social-icon linkedin w-inline-block"></a>
                            </div>
                        </div>
                    </div>
                    <div className="w-col w-col-8 w-col-stack">
                        <div className="page-content">
                            <div className="title-wrapper">
                                <h3 className="resume-title">01. About me</h3>
                            </div>
                            <div>
                                <h1 className="about-title">
                                    {data?.data?.snippets?.[0]?.aboutme}
                                </h1>
                            </div>
                            <div className="space-divider"></div>
                            <div className="title-wrapper">
                                <h3 className="resume-title">02. What I do</h3>
                            </div>
                            <div>
                                {ReactHtmlParser(data?.data?.snippets?.[0]?.whatDoIDo.html)}
                            </div>
                            <div className="space-divider"></div>
                            <div className="title-wrapper">
                                <h3 className="resume-title">03. Reference Projects</h3>
                            </div>
                            <div>
                                <div className="w-dyn-list">
                                    <div role="list" className="w-dyn-items">
                                        {data?.data?.projects?.map(project => {
                                            return (
                                                <div role="listitem" className="w-dyn-item" >
                                                    <div className="education-wrapper">
                                                        <div className="w-row">
                                                            <div>
                                                                <div className="education-name">{project.title}</div>
                                                                <div className="education-branch">{project.company} - {project.location}</div>
                                                                <div className="education-keywords"><strong>Keywords:</strong> {project.keywords}</div>
                                                            </div>
                                                            <div>
                                                                <ReactMarkdown>
                                                                    {project.description}
                                                                </ReactMarkdown>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
